<template>
  <div class="ProjectManagement">
    <div
      class="ProjectManagement_left"
      :class="{ ProjectManagement_left1: show == false }"
    >
      <el-menu class="el_menu" :default-active="$route.path" router v-show="show">
        <el-menu-item
          v-for="(item, index) in nav_menu_data"
          :key="index"
          :index="item.path"
        >
          <span slot="title">{{ item.title }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="ProjectManagement_center">
      <img src="../../assets/image/guan.png" @click="close" v-show="show"/>
      <img src="../../assets/image/kai.png" @click="open" v-show="!show"/>
    </div>
    <div
      class="ProjectManagement_right"
      :class="{ ProjectManagement_right1: show == false }"
    >
        <router-view></router-view>
    </div>
  </div>
</template>
<script>
import research from "../../api/research.js";
export default {
  data() {
    return {
      nav_menu_data: [
        {
          title: "统计概览",
          path: "/StatisticalOverview",
        },
        {
          title: "项目列表",
          path: "/ProjectList",
        },
        {
          title: "法律法规",
          path: "/LawsAndRegulations",
        },
        {
          title: "通知通告",
          path: "/NoticeNotice",
        },
        // {
        //   title: "培训交流",
        //   path: "/TrainingJi",
        // },
        {
          title: "讨论区",
          path: "/DiscussionArea",
        },
        {
          title: "问题与反馈",
          path: "/Feedback",
        },
        {
          title: "数据保密协议",
          path: "/Lifesaving",
        },
      ],
      show: true,
    };
  },
  created() {
    // research.allmenu().then((res) => {
    //   res.data.ExtraData.forEach((item) => {
    //     if (item.MenuType == 0) {
    //       this.nav_menu_data.push({
    //         title: item.MenuName,
    //         path: "/personnel",
    //         DataGuid: item.DataGuid,
    //       });
    //     }
        // if (item.Id == 4 && item.MenuType == 1) {
        //   this.menu.push({
        //     text: item.MenuName,
        //     route: "publics",
        //     DataGuid: item.DataGuid,
        //   });
        // }
        // if (item.Id == 5 && item.MenuType == 1) {
        //   this.menu.push({
        //     text: item.MenuName,
        //     route: "knowledge",
        //     DataGuid: item.DataGuid,
        //   });
        // }
    //   });
    // });
  },

  methods: {
    close() {//关
      this.show = false;
       this.$store.commit("showflag", true);
    },
    open(){//关
       this.show = true;
       this.$store.commit("showflag", false);
    }
  },
};
</script>
<style lang="scss" scoped>
.ProjectManagement {
   width: 1920px;
   height: 100%;
  .ProjectManagement_left {
    width: 140px;
    height: 100%;
    float: left;
    transition: width 1s;
    background-color: white;
    box-shadow: 3px 0 6px rgba(0, 104, 163, 0.1);
    .el_menu{
        width: 140px;
      span {
        font-size: 18px;
      }
    }
  }
  .ProjectManagement_left1 {
    width: 0px;
  }
  .ProjectManagement_center {
    width: 30px;
    height: 100%;
    //background: chartreuse;
    float: left;
    position: relative;
    img {
      position: absolute;
      top: 45%;
      width: 30px;
      height: 30px;
    }
  }
  .ProjectManagement_right {
    width: 1750px;
    height: 100%;
    float: left;
    transition: width 1s;
  }
  .ProjectManagement_right1 {
    width: 1890px;
  }
}
/deep/ .el-menu-item.is-active {
  border-left: 4px solid #409eff;
  color: #303133;
  padding-left: 16px!important;
}
</style>
